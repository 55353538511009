$(document).ready(() => {

  $('.js-toggle-communication').click(function () {
    const $form = $(this).parents('.js-communication').find('.js-communication-form')
    const $open = $(this).parents('.js-communication').find('.js-open-communication')
    const $close = $(this).parents('.js-communication').find('.js-close-communication')
    
    if ($form.hasClass('hidden')) {
      $form.removeClass('hidden')
      $open.addClass('hidden')
      $close.removeClass('hidden')
    } else {
      $form.addClass('hidden')
      $close.addClass('hidden')
      $open.removeClass('hidden')
    }
  })
    
})

