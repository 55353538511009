$(document).ready(() => {

  const search = document.getElementsByClassName('js-search')[0]
  const form = document.getElementsByClassName('js-search-form')[0]

  if (search && form) 
    search.addEventListener('keyup', () => { Rails.fire(form, 'submit') })
    
})

